<template>
  <div>
    <vx-card
      class="mb-4"
      :title="$t(resources.Filters.i18n) || resources.Filters.name"
    >
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">
            {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="startCreationDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">
            {{ $t(resources.EndDate.i18n) || resources.EndDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="endCreationDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.BookingCode.i18n) || resources.BookingCode.name
              "
              maxlength="20"
              v-model="bookingCode"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <vs-select
              class="w-full"
              icon-no-border
              :label="$t(resources.Managed.i18n) || resources.Managed.name"
              v-model="managed"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in managedOption"
              />
            </vs-select>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Type.i18n) || resources.Type.name
            }}</label>
            <v-select
              v-model="type"
              :clearable="true"
              :options="typeOption"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Country.i18n) || resources.Country.name
            }}</label>
            <v-select
              v-model="country"
              :clearable="true"
              :options="countryOption"
              :reduce="item => item.countryCode"
              label="countryName"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
              "
              v-validate="'numeric|min_value:1'"
              v-model="orderNumber"
              name="orderNumber"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{
              errors.first("orderNumber")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <vs-select
              class="w-full"
              icon-no-border
              :label="$t(resources.Discount.i18n) || resources.Discount.name"
              v-model="discount"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in discountOptions"
              />
            </vs-select>
          </div>
        </div>
        <div class="vx-col mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            class="mr-1"
            @click="addfilters()"
            >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button
          >
          <vs-button color="warning" type="filled" @click="cleanFilters()">{{
            $t(resources.Clean.i18n) || resources.Clean.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>

    <vs-row vs-type="flex" vs-justify="center" class="mb-4">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
        class="mr-2"
      >
        <vx-card card-background="success" content-color="#fff">
          <h5 class="text-center text-white">
            {{ $t(resources.Total.i18n) || resources.Total.name }}
          </h5>
          <h1 class="text-center text-white">
            <strong>${{ total }}</strong>
          </h1>
        </vx-card>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
      >
        <vx-card card-background="success" content-color="#fff">
          <h5 class="text-center text-white">
            {{
              $t(resources.EarningsAmount.i18n) || resources.EarningsAmount.name
            }}
          </h5>
          <h1 class="text-center text-white">
            <strong>${{ earningsAmount }}</strong>
          </h1>
        </vx-card>
      </vs-col>
    </vs-row>

    <div id="ag-grid-demo mt-5" style="padding-top: 7px">
      <vx-card :title="$t(resources.Bookings.i18n) || resources.Bookings.name">
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    accessData.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : accessData.length
                  }}
                  of {{ totalRows }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="accessData"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalNumberPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import vSelect from "vue-select";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";

export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker
  },
  data() {
    return {
      languages: lang,
      language: this.$i18n.locale,
      bookingCode: localStorage.getItem("bookingCode"),
      distributorID: localStorage.getItem("userInfo"),
      gds: parseInt(localStorage.getItem("supplierId")),
      discount: localStorage.getItem("isDiscount"),
      orderNumber: "",
      gdsOption: [],
      source: parseInt(localStorage.getItem("bookingSourceId")),
      sourceOption: [],
      resources: resources,
      alerts: alerts,
      activeConfirm: false,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      accessData: [],
      totalNumberPages: 0,
      totalRows: 0,
      country: localStorage.getItem("countryCode"),
      countryOption: [],
      type: parseInt(localStorage.getItem("productId")),
      typeOption: [],
      managed: "",
      stage: parseInt(localStorage.getItem("stageId")),
      startCreationDate: localStorage.getItem("startCreationDate"),
      endCreationDate: localStorage.getItem("endCreationDate"),
      earningsAmount: 0,
      total: 0,
      managedOption: [
        { text: "All", value: "", i18n: "All" },
        { text: "No", value: "0", i18n: "No" },
        { text: "Yes", value: "1", i18n: "Yes" }
      ],
      discountOptions: [
        { text: "All", value: "", i18n: "All" },
        { text: "No", value: "0", i18n: "No" },
        { text: "Yes", value: "1", i18n: "Yes" }
      ],
      filterData: {},
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "bookingId",
          filter: true,
          hide: true
        },
        {
          headerName: this.$i18n.t("Paid"),
          field: "isPaid",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.isPaid) {
              case 0:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case 1:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              default:
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("WayToPay"),
          field: "merchantCode",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "countryCode",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "productCode",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "bookingReference",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("OrderNumber"),
          field: "infotraxOrder",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "itacommission",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("BVPoints"),
          field: "bvPoints",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Currency"),
          field: "currencyCode",
          filter: true
        },
        {
          headerName: this.$i18n.t("BookingValue"),
          field: "total",
          filter: true
        },
        {
          headerName: this.$i18n.t("DiscountValue"),
          field: "discount",
          filter: true
        },
        {
          headerName: this.$i18n.t("CustomizedFee"),
          field: "feeCustomized",
          filter: true
        },
        {
          headerName: `${this.$i18n.t("ExchangeRate")} - ${this.$i18n.t(
            "CustomizedFee"
          )}`,
          field: "customizedRateValue",
          filter: true,
          width: 220
        },
        {
          headerName: this.$i18n.t("Source"),
          field: "bookingSourceName",
          filter: true
        },
        {
          headerName: this.$i18n.t("Stage"),
          field: "stageName",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "createdDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("StartDate"),
          field: "startDate",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.totalNumberPages)
        return this.totalNumberPages.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: function() {
    this.getCountries();
    this.getProducts();
    this.addfilters();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async addfilters() {
      this.filterData = {};
      this.$set(this.filterData, "itaCode", this.itaCode);

      if (this.managed != "" && this.managed != null)
        this.$set(this.filterData, "isManaged", this.managed);
      else localStorage.setItem("isManaged", "");

      if (this.bookingCode != "" && this.bookingCode != null) {
        this.$set(this.filterData, "bookingCode", this.bookingCode);
        localStorage.setItem("bookingCode", this.bookingCode);
      } else localStorage.setItem("bookingCode", "");

      if (this.country != "" && this.country != null) {
        this.$set(this.filterData, "countryCode", this.country);
        localStorage.setItem("countryCode", this.country);
      } else localStorage.setItem("countryCode", "");

      if (this.type != 0 && this.type != null && !isNaN(this.type)) {
        this.$set(this.filterData, "productId", this.type);
        localStorage.setItem("productId", this.type);
      } else localStorage.setItem("productId", 0);

      if (this.startCreationDate != "" && this.startCreationDate != null) {
        let _date = moment(this.startCreationDate).format("YYYY-MM-DD");
        this.$set(this.filterData, "startCreationDate", _date);
        localStorage.setItem("startCreationDate", _date);
      } else localStorage.setItem("startCreationDate", "");

      if (this.endCreationDate != "" && this.endCreationDate != null) {
        let _date = moment(this.endCreationDate).format("YYYY-MM-DD");
        this.$set(this.filterData, "endCreationDate", _date);
        localStorage.setItem("endCreationDate", _date);
      } else localStorage.setItem("endCreationDate", "");

      if (this.orderNumber != "" && this.orderNumber != null) {
        this.$set(this.filterData, "orderNumber", this.orderNumber);
        localStorage.setItem("orderNumber", this.orderNumber);
      } else localStorage.setItem("orderNumber", "");

      if (this.discount != "" && this.discount != null) {
        this.$set(this.filterData, "isDiscount", this.discount);
        localStorage.setItem("isDiscount", this.discount);
      } else localStorage.setItem("isDiscount", "");

      this.getBookings();
    },

    async cleanFilters() {
      this.bookingCode = "";
      this.type = 0;
      this.managed = "";
      this.filterData = {};
      this.country = "";
      this.startCreationDate = "";
      this.endCreationDate = "";
      this.$set(this.filterData, "isManaged", this.managed);
      localStorage.setItem("bookingCode", "");
      localStorage.setItem("itaCode", "");
      localStorage.setItem("countryCode", "");
      localStorage.setItem("productId", this.type);
      localStorage.setItem("startCreationDate", this.startCreationDate);
      localStorage.setItem("endCreationDate", this.endCreationDate);
      this.addfilters();
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProducts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/bytype/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [];
          } else {
            this.typeOption = result.data.filter(
              x => x.code != "COU" && x.code != "CPR"
            );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getBookings() {
      this.$vs.loading();

      let searchKeysData = this.filterData;

      await axios({
        method: "POST",
        url: process.env.VUE_APP_APIVT + "Booking/ListByFilters",
        data: {
          searchKeys: searchKeysData,
          page: this.NumberPage,
          rows: this.rows,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accessData = [];
            this.totalRows = 0;
            this.totalNumberPages = 0;
            this.earningsAmount = 0;
            this.total = 0;
          } else {
            this.accessData = result.data.data;
            this.totalRows = result.data.total;
            this.totalNumberPages = Math.round(result.data.total / this.rows);
            this.earningsAmount = 0;
            this.total = 0;
            this.accessData.forEach(item => {
              item.commissionRateValue =
                parseFloat(item.commissionRateValue) === 0
                  ? 1
                  : parseFloat(item.commissionRateValue);
              this.earningsAmount += parseFloat(item.itacommission);
              if (parseFloat(item.itacommission) > 0)
                this.total +=
                  parseFloat(item.total) / parseFloat(item.commissionRateValue);
            });

            this.earningsAmount = this.earningsAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total = this.total
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.accessData = [];
        }
      );
      this.$vs.loading.close();
    },

    async onRowClicked(event) {
      let bookingId = event.node.data.bookingId;
      this.$router.push({
        name: "bookingDetail",
        params: {
          bookingId: bookingId
        }
      });
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
